import {
  createCollectionPoint,
  updateCollectionPoint,
} from "../../../../../features/collectionPointSlice/collectionPointSlice";
import { useDispatch, useSelector } from "react-redux";

import Content from "./partials/Content";
import Modal from "../../../../../components/atoms/Modal";
import React from "react";
import { omitBy } from "lodash";
import { selectModal } from "../../../../../features/modalSlice";
import { selectUser } from "../../../../../features/userSlice";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import * as yup from "yup";

export const formSchema = yup.object().shape({
  name: yup.string().required("Jumlah tph wajib diisikan"),
});

const MultiModal = ({
  isOpen,
  toggleModal,
  type,
  onRefreshData,
  estate,
  afdeling,
  block,
}) => {
  const UPDATE_TYPE = type.toLowerCase().includes("ubah");
  const dispatch = useDispatch();
  const alert = useAlert();
  const modalName = "CollectionPoint";
  const modal = useSelector(selectModal);
  const user = useSelector(selectUser);
  const [searchParams] = useSearchParams();

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    clearErrors,
  } = useForm({ resolver: yupResolver(formSchema) });

  const estateId = searchParams.get("estateId");
  const estateName = searchParams.get("estateName");
  const afdelingId = searchParams.get("afdelingId");
  const afdelingName = searchParams.get("afdelingName");
  const blockId = searchParams.get("blockId");
  const blockName = searchParams.get("blockName");

  const onSubmit = (data) => {
    let requestData = {
      ...data,
      afdelingId,
      afdelingName,
      estateId,
      estateName,
      blockId,
      blockName,
      userId: user.id,
      userName: user.username,
      companyId: user.companyId,
    };

    if (UPDATE_TYPE) {
      return dispatch(updateCollectionPoint(requestData))
        .unwrap()
        .then((response) => {
          const success = response.success;
          const message = response.message;
          if (!success) {
            return alert.error(message);
          }
          alert.success(`TPH ${data.name} berhasil diperbaharui`);
          toggleModal();
          onRefreshData();
        });
    }

    requestData = omitBy(requestData, (v) => v == null);
    dispatch(createCollectionPoint(requestData))
      .unwrap()
      .then((response) => {
        const success = response.success;
        const message = response.message;
        if (!success) {
          return alert.error(message);
        }

        alert.success(`TPH ${data.name} berhasil dibuat`);
        toggleModal();
        onRefreshData();
      });
  };

  const handleDefaultValue = () => {
    let state = modal[modalName]?.params?.item;
    clearErrors();
    reset();
    if (UPDATE_TYPE) {
      const currentData = state;
      reset(currentData);
    } else {
      const nullValues = Object.keys(state || {}).reduce((accumulator, key) => {
        return { ...accumulator, [key]: null };
      }, {});
      reset(nullValues);
    }
  };

  React.useEffect(() => handleDefaultValue(), [type, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={toggleModal}
      title={`${type} ${estate.name} / ${afdeling.name} / ${block.name}`}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Content>
          <Content register={register} errors={errors} />
        </Modal.Content>
        <Modal.Footer toggleModal={toggleModal} type={type} />
      </form>
    </Modal>
  );
};

export default MultiModal;
